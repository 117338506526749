<template>
  <transition name="fade-up">
    <div class="section-title" v-if="displaySectionTitle">
      <div class="section-bullet" :style="{'top': (phase * 1.57 - 0.90) + 'em'}"></div>
      <button class="phase-button" id="sectionButton1"
              :class="{'now-in': phase === 1}" @click="changePhase(1)">About
      </button>
      <br/>
      <button class="phase-button" id="sectionButton2"
              :class="{'now-in': phase === 2}" @click="changePhase(2)">Biography & Skill Set
      </button>
      <br/>
      <button class="phase-button" id="sectionButton3"
              :class="{'now-in': phase === 3}" @click="changePhase(3)">Portfolios
      </button>
      <br/>
      <button class="phase-button" id="sectionButton4"
              :class="{'now-in': phase === 4}" @click="changePhase(4)">Philosophy in Work
      </button>
      <br/>
      <button class="phase-button" id="sectionButton5"
              :class="{'now-in': phase === 5}" @click="changePhase(5)">More
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SubtitleList',
  props: ['displaySectionTitle', 'phase'],
  data () {
    return {
      fadeInDuration: 1000
    }
  },
  mounted () {
    for (let i = 1; i <= 5; i++) {
      this.$anime({
        delay: i * 200,
        targets: '#sectionButton' + i,
        translateY: [20, 0],
        opacity: [0, 1],
        easing: 'cubicBezier(0.595, 0.025, 0.415, 0.975)',
        duration: this.fadeInDuration
      })
    }
    this.$anime({
      delay: this.phase * 200 + 400,
      targets: '.section-bullet',
      translateX: [-20, 0],
      opacity: [0, 1],
      easing: 'cubicBezier(0.595, 0.025, 0.415, 0.975)',
      duration: this.fadeInDuration
    })
  },
  methods: {
    changePhase (n) {
      this.$emit('change-phase', n)
    }
  }
}
</script>

<style scoped>
  .section-title-appear-enter-active, .section-title-appear-leave-active {
    transition: 1s cubic-bezier(0.700, 0, 0.580, 1.000) !important;
  }

  .section-title-appear-enter, .section-title-appear-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    transition: 4s cubic-bezier(0.700, 0, 0.580, 1.000) !important;
    opacity: 0;
    transform: translateY(20px);
  }

  .name {
    padding: 30px;
    font-size: var(--h2-size);
    font-style: italic;
  }

  .brief-about {
    font-size: var(--h3-size);
    font-style: italic;
  }

  .phase-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 21px;
    font-size: var(--body-size);
    color: var(--content-color);
    outline: none;
    letter-spacing: .1px;
  }

  .phase-button:hover {
    opacity: 1;
    color: var(--blue-stragglr)
  }

  .go-to {
    padding: 20px;
    font-size: var(--body-size);
  }

  .scroll {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .section-title {
    position: relative;
    margin-top: 30px;
    animation-delay: 3s;
    transition-delay: 3s;
    transition: .5s ease-in-out;
    opacity: 0;
    text-align: left;
    width: 300px;
    font-weight: 700;
  }
  @media screen and (max-width: 1280px){
    .section-title {
      width: 240px;
    }
  }

  .scrolled .section-title {
    opacity: 1;
  }

  .section-bullet {
    transition: .125s;
    content: ' ';
    position: absolute;
    left: -8px;
    top: 6px;
    width: 8px;
    height: 8px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid var(--blue-stragglr);
  }

  .now-in {
    font-weight: 700;
    position: relative;
    letter-spacing: -.1px;
    text-decoration: underline;
  }

</style>
