<template>
  <div v-if="phase === 0">
    <div class="name" id="transitionName">blueStragglr</div>
    <div class="brief-about" id="transitionAbout">
      Web Front-end Dev. / UXUI Designer
    </div>
    <div class="go-to" id="transitionGoto">
      <button class="phase-button" @click="changePhase(1)">About</button>
      |
      <button class="phase-button" @click="changePhase(2)">Biography & Skill Set</button>
      |
      <button class="phase-button" @click="changePhase(3)">Portfolios</button>
      |
      <button class="phase-button" @click="changePhase(4)">Philosophy in Work</button>
      |
      <button class="phase-button" @click="changePhase(5)">More</button>
    </div>
    <div class="scroll" id="transitionScroll">
      <span v-show="isIos">Touch Menu</span>
      <span v-show="!isIos">Scroll down</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainTextContent',
  props: ['phase', 'isIos'],
  data () {
    return {
      fadeInDuration: 500,
      showScrollNoti: false,
      animationDelay: 1400
    }
  },
  mounted() {
    this.$anime({
      delay: 1000 + this.animationDelay,
      targets: '#transitionName',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'cubicBezier(0.595, 0.025, 0.415, 0.975)',
      duration: this.fadeInDuration
    })
    this.$anime({
      delay: 1200 + this.animationDelay,
      targets: '#transitionAbout',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'cubicBezier(0.595, 0.025, 0.415, 0.975)',
      duration: this.fadeInDuration
    })
    this.$anime({
      delay: 1400 + this.animationDelay,
      targets: '#transitionGoto',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'cubicBezier(0.595, 0.025, 0.415, 0.975)',
      duration: this.fadeInDuration
    })
    this.$anime({
      delay: 1600 + this.animationDelay,
      targets: '#transitionScroll',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'cubicBezier(0.595, 0.025, 0.415, 0.975)',
      duration: this.fadeInDuration
    })
    this.$anime({
      delay: 2200 + this.animationDelay,
      targets: '#scrollDown',
      translateY: [20, 0],
      opacity: [0, 1],
      easing: 'cubicBezier(0.595, 0.025, 0.415, 0.975)',
      duration: this.fadeInDuration
    })
  },
  methods: {
    changePhase(n){
      this.$emit('change-phase', n)
    }
  }
}
</script>

<style scoped>

  .name {
    padding: 30px;
    font-size: var(--h2-size);
    font-style: italic;
  }

  .brief-about {
    font-size: var(--h3-size);
    font-style: italic;
  }

  .phase-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: var(--body-size);
    color: var(--content-color);
    outline: none;
  }

  .phase-button:hover {
    opacity: 1;
    color: var(--blue-stragglr)
  }

  .go-to {
    padding: 20px;
    font-size: var(--body-size);
  }

  .scroll {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .section-title {
    margin-top: 30px;
    animation-delay: 3s;
    transition-delay: 3s;
    transition: .5s ease-in-out;
    opacity: 0;
    text-align: left;
    width: 300px;
    font-weight: 700;
  }

  .scrolled .section-title {
    opacity: 1;
  }

</style>
