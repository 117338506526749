import { render, staticRenderFns } from "./CodeitBlog.vue?vue&type=template&id=8c09561c&scoped=true&"
import script from "./CodeitBlog.vue?vue&type=script&lang=js&"
export * from "./CodeitBlog.vue?vue&type=script&lang=js&"
import style0 from "./CodeitBlog.vue?vue&type=style&index=0&id=8c09561c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c09561c",
  null
  
)

export default component.exports