<template>
  <div class="content-wrapper">
    <PageTitle>
      <div class="title">More</div>
    </PageTitle>
    <div class="content" id="content">
      <div class="philosophy-wrapper">
        <div class="philosophy-text stagger-list">
          <br/>
          <SubTitle>
            조금은 사적인 자기소개
          </SubTitle>
          카이스트에서 물리학을 전공하다 즐거움을 찾아 🎨디자인과 💻프론트엔드 개발의 길을 걷게 되었습니다.<br/>
          멋진 사람들과 어울리고 함께하는 것에서 즐거움을 느낍니다.<br/>
          세상에 필요한 서비스를 즐겁게 만드는 개발모임 <a target="_blank" href="https://prography.org/">프로그라피</a>에서
          2018년부터 2023년까지 운영진으로 활동하였습니다.<br/><br/>
          Vue 2 및 3 공식문서 번역 모두에
          <a target="_blank"
             href="https://github.com/vuejs-kr/kr.vuejs.org/pulls?q=is%3Apr+is%3Aclosed+author%3AblueStragglr">참여</a>하였습니다.
          <a target="_blank" href="https://velog.io/@bluestragglr">기술 블로그</a>에도
          배운 것을 누구나 따라할 수 있도록 정리하고 있습니다.<br/>
          우아한형제들에서는
          <a target="_blank" href="https://www.youtube.com/live/9AUEfGZslmw?feature=share">
            유튜브 세미나
          </a>나
          <a target="_blank" href="https://woowacon.com/ko/2022/detailVideo/22">
            우아한 테크콘서트
          </a> 등에서 심도있는 지식을 나누며 개발자의 역할과 (상대적) 시니어 개발자로써의 역할을 수행하고 있습니다.<br/><br/>
          다양한 취미를 가지고 있습니다. 음악과 요리, 맛있는 음식과 운동을 좋아합니다. 밴드 활동을 하며, 다양한 직군의 좋은 사람들과 즐겁게 어울리는 생활을 하고 있습니다.<br/>
          <br/>
        </div>
        <div class="philosophy-text stagger-list">
          <SubTitle>
            Contact +
          </SubTitle>
          Email: Oortcloud1599@gmail.com<br/>
          Github: <a target="_blank" href="https://github.com/blueStragglr">blueStragglr</a><br/>
          Velog: <a target="_blank" href="https://velog.io/@bluestragglr">@blueStragglr</a><br/>
          <br/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '../atoms/PageTitle'
import SubTitle from '../atoms/SubTitle'
import AnimationTitle from '../atoms/AnimationTitle'
import AnimationDelay from '../atoms/AnimationDelay'
import ListStagger from '../atoms/ListStagger'

export default {
  name: 'More',
  components: { SubTitle, PageTitle },
  mixins: [AnimationDelay, AnimationTitle, ListStagger],
}
</script>

<style scoped>
  .content-wrapper {
    font-family: 'Spoqa Han Sans', sans-serif;
    text-align: left;
    width: 100%;
    max-width: 1280px;
    height: 100vh;
    padding: var(--top-bottom-padding) 15px 0 0;
  }

  .content {
    margin-top: 16px;
    display: flex;

    align-items: center;
    align-content: center;
  }

  .philosophy-wrapper {
    display: flex;
    flex-direction: column;
  }

  .philosophy-text:first-child {
    margin-right: 40px;
  }

  .philosophy-text {
    font-size: 14px;
    line-height: 1.6;
    flex: 1;
  }

  .something-complicated {
    display: inline-block;
    position: relative;
    white-space: pre;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.3);
  }

  .detail {
    position: absolute;
    bottom: -28px;
    left: -6px;
    padding: 4px 8px;
    background-color: rgba(255, 255, 255, 0.9);
    color: var(--space-black);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;
    transition: .25s ease-in;
  }

  .something-complicated:hover .detail {
    transform: translateY(0);
    opacity: 1;
    visibility: inherit;
  }

  @media screen and (max-width: 1280px) {
    .large-screen-only {
      display: none;
    }
  }

</style>
