<template>
  <div class="home" :class="{'home-scrolled': displaySectionTitle}">
    <div class="scroll-meter">
      <div class="upper">
        <div class="upper-bar" :style="{'height': upperBarHeight * 100 + 'vh'}"></div>
      </div>
    </div>
    <div class="blue-stragglr" :class="{'scrolled': displaySectionTitle}">
      <svg @click="changePhase(0)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 196.61" id="blueStragglr_Objet">
        <title>blueStragglr</title>
        <g id="layer1">
          <g id="layer2">
            <circle class="orange lines" cx="85.36" cy="85.36" r="84.86"/>
            <circle class="blue lines" cx="110.4" cy="107" r="89.1"/>
          </g>
        </g>
      </svg>
      <transition name="section-title-appear">
        <SubtitleList :display-section-title="displaySectionTitle" :phase="phase" :key="'section'+displaySectionTitle"
                      @change-phase="changePhase"/>
      </transition>
    </div>
    <div>
      <MainTextContent :is-ios="isiOS" :phase="phase" :key="'Main'+phase" @change-phase="changePhase"/>
    </div>
    <About v-if="phase === 1"/>
    <BiographySkillSet v-if="phase===2"/>
    <PortFolio v-if="phase===3"/>
    <PhilosophyInWork v-if="phase===4"/>
    <More v-if="phase===5"/>
  </div>
</template>

<script>
import MainTextContent from '../components/MainTextContent'
import SubtitleList from '../components/SubtitleList'
import About from '../components/About'
import BiographySkillSet from '../components/BiographySkillSet'
import PortFolio from '../components/PortFolio'
import PhilosophyInWork from '../components/PhilosophyInWork'
import More from '../components/More'
import AnimationTitle from '../atoms/AnimationTitle'
import AnimationDelay from '../atoms/AnimationDelay'

export default {
  name: 'Home',
  components: { More, PhilosophyInWork, PortFolio, BiographySkillSet, About, SubtitleList, MainTextContent },
  mixins: [AnimationDelay, AnimationTitle],
  data () {
    return {
      phase: 0,
      scrollAmount: 0,
      sectionLength: 6,
      scrollPos: 0,
      phaseSize: 2400,
      isiOS: false
    }
  },
  mounted () {
    this.isiOS = this.$store.state.isDeviceIos
    this.$anime({
      targets: '#blueStragglr_Objet',
      rotate: '360deg',
      duration: 10000,
      delay: 3000,
      easing: 'linear',
      loop: true,
    })
    const wheelInteraction = (e) => {
      this.scrollPos += e.deltaY
      if (this.scrollPos <= 0) {
        this.scrollPos = 0
      }
      if (this.scrollPos >= this.sectionLength * this.phaseSize) {
        this.scrollPos = this.sectionLength * this.phaseSize - 1
      }
      e.preventDefault();
    }
    if (!this.isiOS) {
      window.addEventListener('wheel', wheelInteraction, { passive: false })
      window.addEventListener('DOMMouseScroll', wheelInteraction, { passive: false })
    }
    window.setInterval(() => {
      this.calcPhase()
    }, 100)
  },
  methods: {
    changePhase (n) {
      this.scrollPos = this.phaseSize * n
      this.updatePhase(n)
      window.setTimeout(() => {
        this.$store.commit('updatePrevPhase', this.phase)
      }, 2400)
    },
    updatePhase (phaseN) {
      if (this.phase !== phaseN || phaseN === 0) {
        this.$store.commit('updatePrevPhase', this.phase)
      }
      this.phase = phaseN
    },
    calcPhase () {
      this.updatePhase(Math.floor(this.scrollPos / this.phaseSize))
    },
    delay (t, v) {
      return new Promise(function (resolve) {
        setTimeout(resolve.bind(null, v), t)
      });
    }
  },
  computed: {
    upperBarHeight () {
      return Math.max((this.scrollPos - this.phase * this.phaseSize) / this.phaseSize, 0)
    },
    displaySectionTitle () {
      return this.phase !== 0
    },
    wasZero () {
      return this.prevPhase === 0
    }
  }
}
</script>
<style scoped>
  .home {
    text-align: center;
    position: relative;
    height: 100vh;
    width: 100%;

    display: flex;
    flex: 1;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
  }

  .home.home-scrolled {
    padding-left: 240px;
  }

  @media screen and (max-width: 1280px) {
    .home.home-scrolled {
      padding-left: 220px;
    }
  }

  .scroll-meter {
    position: fixed;
    right: 0;
    top: 0;
    width: 3px;
    height: 100vh;
  }

  .upper-bar {
    position: absolute;
    top: 0;
    width: 3px;
    opacity: .5;
    background-color: var(--blue-stragglr);
  }

  .upper {
    height: 100vh;
    position: relative;
  }

  .blue-stragglr {
    position: absolute;
    top: calc(50vh - 240px);
    left: 0;
    right: 0;
    margin: -60px auto 0;
    width: 200px;
    height: 200px;
    transition: top 1.6s ease-in, left 1.3s ease-in-out, right 1.6s ease-in-out, width 1s linear, height 1s linear;
  }

  .blue-stragglr.scrolled {
    top: calc(var(--top-bottom-padding) + 40px);
    right: calc(100vw - 120px);
    left: 40px;
    /*right: unset;*/
    margin: -60px auto 0;
    width: 80px;
    height: 80px;
  }

  @media screen and (max-width: 1280px) {

    .blue-stragglr.scrolled {
      right: calc(100vw - 100px);
    }
  }

  .orange, .blue {
    fill: none;
    stroke-width: 2;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }
  .orange{
    animation: dash 3s ease-in forwards;
  }
  .blue {
    transform: rotateY(360deg);
    animation: dash 5.4s ease-out forwards;
  }
  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
  .orange {
    stroke: #f3a77e;
  }

  .blue {
    stroke: #aee0ee;
    fill: none;
  }
</style>
