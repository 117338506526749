<template>
  <div>
    <div class="stagger-list timeline-label">
      <span class="">- Biography</span>
    </div>
    <div class="biography-table">
      <div v-for="(biographyElement, index) in biography" :key="index" class="stagger-list biography-table__row">
        <div class="biography-table__timestamp">
          {{ biographyElement.duration }}
        </div>
        <div class="biography-table__detail" v-html="biographyElement.content"/>
      </div>
      <div class="stagger-list biography-table__row">
        <div class="biography-table__summary">
          *총 개발경력: <strong>{{currentCareer}}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListStagger from '../../atoms/ListStagger'

export default {
  name: 'BiographyList',
  mixins: [ ListStagger ],
  data () {
    return {
      biography: [
        {
          duration: '2013.03. - 현재',
          content: 'BS, Dept. of Physics, KAIST<br/> School of Computer Science 부전공'
        },
        {
          duration: '2016.11 - 2017. 06',
          content: '두뇌 개발을 위한 기능성 게임 개발 스타트업 Play B 대표<br/> 주식회사 Newpage, 주식회사 Mytic 외주용역(디자인 및 개발)'
        },
        {
          duration: '2017.06 - 2019.09',
          content: 'Blockchain & Big data기반 기술 스타트업, <a href="https://artifriends.com/" rel="noreferrer" target="_blank">ArtiFriends</a> Co-Founder (CDO)'
        },
        {
          duration: '2018.07 - 현재',
          content: '세상에 필요한 서비스를 만드는 개발모임, Prography 운영진'
        },
        {
          duration: '2019.09 - 2019.12',
          content: '부동산 세무관련 스타트업 ArtiWealth 외주용역 개발자 (*ArtiWealth는 ArtiFriends의 자회사입니다.)'
        },
        {
          duration: '2019.12 - 2020. 06.',
          content: '시선추적 기술 스타트업 VisualCamp 프론트엔드 개발자'
        },
        {
          duration: '2020.07 - 2021. 08',
          content: '코딩 교육 스타트업 <a href="https://codeit.kr/" rel="noreferrer" target="_blank">코드잇</a> 프론트엔드 개발자'
        },
        {
          duration: '2021.08 - 현재',
          content: '<a href="https://www.woowahan.com/" rel="noreferrer" target="_blank">우아한형제들</a> 프론트엔드 개발자'
        } ]
    }
  },
  computed: {
    currentCareer () {
      const startedAt = new Date('2017-06-01')
      const today = new Date()
      let yearDiff = today.getFullYear() - startedAt.getFullYear()
      let monthDiff = today.getMonth() - startedAt.getMonth() - 1
      if (monthDiff < 0) {
        yearDiff -= 1;
        monthDiff += 12
      }
      return `${ yearDiff }년 ${ monthDiff }개월`
    }
  }
}
</script>

<style scoped>
  .timeline-label {
    padding: 0 0 4px;
    font-size: var(--h2-size);
    margin: 32px 0 12px;
  }

  .timeline-label > span {
    font-weight: 700;
    /*border-bottom: 1px solid var(--content-color);*/
  }

  .biography-table__row {
    display: flex;
    width: 100%;
    font-size: var(--body-size);
    padding: 4px 0 8px;
  }

  .biography-table__timestamp {
    flex: none;
    width: 200px;
    font-weight: 600;
  }

  .biography-table__detail {
    flex: 1;
  }

  .biography-table__summary {
    flex: 1;
    font-size: var(--body-size);
  }
</style>
